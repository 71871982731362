<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Blog</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Blog List</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row  class="mb-2">
              <b-col md="8"></b-col>
              <!-- $bvModal.show('bv-modal-blog') -->
              <b-col md="2"><base-button block type="primary" @click="$router.push({name: 'addBlog'})" >Add Blog</base-button></b-col>
              <b-col md="2"><base-button block type="primary">Export to CSV</base-button></b-col>
            </b-row>
            <b-card type="default" no-body>
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                enabled: true,
                placeholder: 'Search this table',
                }"
                :pagination-options="{
                enabled: true,
                mode: 'records'
                }"
                >
                  <template slot="table-row" slot-scope="props" style="w">
                    <span v-if="props.column.field == 'before'">
                      <img :src="props.row.news_image" width="100%" />
                    </span>
                    <span v-if="props.column.field == 'newsContent'">
                      <base-button
                        icon type="primary"
                        size="sm"
                        >
                        <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                      </base-button>
                    </span>
                    <span v-if="props.column.field == 'after'">
                        <base-button
                        icon type="primary"
                        size="sm"
                        @click="$router.push({name: 'addBlog',params:{data: props.row}})"
                        >
                        <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                        </base-button>
                        <base-button
                        icon type="danger"
                        size="sm"
                        @click="checkedClick(props.row.news_id)"
                        >
                        <span class="btn-inner--icon">
                          <i class="ni ni-check-bold" v-if="props.row.news_status"></i>
                          <i class="ni ni-fat-remove" v-else></i>
                        </span>
                        </base-button>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
            </b-card>
        </b-container>
        <b-modal id="bv-modal-blog" hide-footer>
          <template #modal-title>
            <h2 style="margin:0px">Add Blogs</h2>
          </template>
          <div class="d-block text-center">
            
          </div>
        </b-modal>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api';
export default {
  name: 'blogList',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: true,
      columns: [
        {
          label: 'Image',
          field: 'before',
          width: '150px',
        },
        {
          label: 'Name',
          field: 'news_title'
        },
        {
          label: 'URL',
          field: 'news_url'
        },
        {
          label: 'Categories',
          field: 'news_cat_name'
        },
        {
          label: 'Small Content',
          field: 'news_sub_title'
        },
        {
          label: 'Meta Title',
          field: 'news_metaTitle'
        },
        {
          label: 'Meta Description',
          field: 'news_metaDes'
        },
        {
          label: 'Meta Key Word',
          field: 'news_metaKey'
        },
        {
          label: 'Long Content',
          field: 'newsContent'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ], 
    }
  },
  computed: {
    rows () {
      return this.$store.state.newsList == null ? [] : this.$store.state.newsList
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.news_id === id) {
          row.news_status = !row.news_status; 
          let active = row.news_status;
          this.axios.post(API_URL+'admin_news_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
              this.$store.dispatch('getNewsShow');
              this.$store.dispatch('alertSuccess', message)
            }else {
              this.$store.dispatch('alertSuccess', message)
            }
          })
        }
        return row
      })
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
#bv-modal-blog{
  @media (min-width: 576px){
    .modal-dialog {
        max-width: 900px !important;
    }
  }
}
</style>